<template>
  <b-card>
    <b-overlay
      :show="!affiliatePayments"
      rounded="sm"
    >
      <div class="invoice-register-table pt-1">
        <div class="float-left ir-btn-wrapper">
          <b-button
            v-b-modal.export-modal
            variant="primary"
            class="ir-btn billing-top-btn"
          >
            Export
          </b-button>
        </div>

        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
              />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          v-if="affiliatePayments"
          ref="printMe"
          :columns="columns"
          :rows="affiliatePayments"
          :search-options="{
            enabled: false,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
          class="mt-2"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!-- Column: Detail -->
            <span
              v-if="props.column.field === 'details'"
              class="font-weight-bold"
            >
              <router-link
                :to="`/billing/affiliate-payments-detail/${props.row.id}`"
              >
                <b-button
                  variant="primary"
                  size="sm"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="12"
                  />
                  View Details
                </b-button>
              </router-link>
            </span>

            <span
              v-else-if="props.column.field === 'cumtomAmount'"
            >
              <b-badge :variant="statusVariant(props.row.customStatus)">
                <b> {{ props.row.cumtomAmount }} </b>
              </b-badge>
            </span>

            <span
              v-else-if="props.column.field === 'customStatus'"
            >
              <b-badge :variant="statusVariant(props.row.customStatus)">
                <b> {{ props.row.customStatus }} </b>
              </b-badge>
            </span>

            <!-- Column: download -->
            <span
              v-else-if="props.column.field === 'invoice_path'"
              class="font-weight-bold"
            >
              <a
                href="#"
                @click="downloadUserInvoice(props.row.invoice_path)"
              >download</a>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap mt-1">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','15','20']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </div>
    </b-overlay>
    <b-modal
      id="export-modal"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      title="Export Table"
    >
      <div class="text-center">
        <b-button
          variant="primary"
          class="activation-btn"
          @click="downloadCSV"
        >
          To CSV
        </b-button><br>
        <!-- <b-button
          variant="primary"
          class="activation-btn mt-1 mb-1"
          @click="downloadExcel"
        >
          To Excel
        </b-button> -->
        <download-excel
          style="margin:10px; width: 16rem"
          class="btn btn-primary"
          :data="json_data"
          :fields="json_fields"
          worksheet="Affiliates"
          name="Affiliates.xls"
        >
          To Excel
        </download-excel>
      </div>
    </b-modal>
  </b-card>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  BPagination, BFormSelect, BCard, BOverlay, BButton, BBadge, BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import moment from 'moment'
import vSelect from 'vue-select'
import Vue from 'vue'
import JsonExcel from 'vue-json-excel'
import axios from '@/libs/axios'

Vue.component('downloadExcel', JsonExcel)

export default {
  components: {
    VueGoodTable,
    BPagination,
    BOverlay,
    BButton,
    BCard,
    BModal,
    BFormGroup,
    BFormSelect,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BBadge,
  },
  data() {
    return {
      pageLength: 20,
      columns: [
        {
          label: 'ID',
          field: 'id',
          sortable: true,
          sortFn: this.sortFnID,
        },
        {
          label: 'Request Date',
          field: 'customRequestDate',
          sortable: true,
          sortFn: this.sortFnRegDate,
        },
        {
          label: 'User',
          field: 'email',
        },
        {
          label: 'Amount',
          field: 'cumtomAmount',
          sortable: true,
          sortFn: this.sortFnAmount,
        },
        {
          label: 'Payment Method',
          field: 'payment_method',
          sortable: false,
        },
        {
          label: 'Payment Account',
          field: 'customPaymentAccount',
          sortable: false,
        },
        {
          label: 'Status',
          field: 'customStatus',
          sortable: false,
        },
        {
          label: 'Payment Date',
          field: 'customPaymentDate',
          sortable: true,
          sortFn: this.sortFnPayDate,
        },
        {
          label: 'Invoice',
          field: 'invoice_path',
          sortable: false,
        },
        {
          label: 'Details',
          field: 'details',
          sortable: false,
        },
      ],
      searchTerm: '',
      json_fields: {
        ID: 'id',
        'Request Date': 'request_date',
        User: 'user',
        Amount: 'amount',
        'Payment Method': 'payment_method',
        'Payment Account': 'payment_account',
        Status: 'status',
        'Payment Date': 'payment_date',
      },
      json_data: [],
    }
  },
  computed: {
    affiliatePayments() {
      return this.$store.state.invoice.affiliatePayments
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Paid : 'light-success',
        Pending : 'light-danger',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  mounted() {
    const refreshId = setInterval(() => {
      const tableId = this.$refs.printMe
      if (tableId) {
        this.getExcelData()
        clearInterval(refreshId)
      }
    }, 1000)
  },
  created() {
    this.fetchAffiliatePayments()
  },

  methods: {
    fetchAffiliatePayments() {
      this.$store.dispatch('invoice/fetchAffiliatePayments')
        .catch(error => { console.log(error) })
    },

    getExcelData() {
      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table

      Object.keys(el.rows).forEach(key => {
        if (key >= 1) {
          const row = {
            id: el.rows[key].cells[0].textContent.trim(),
            request_date: el.rows[key].cells[1].textContent.trim(),
            user: el.rows[key].cells[2].textContent.trim(),
            amount: el.rows[key].cells[3].textContent.trim(),
            payment_method: el.rows[key].cells[4].textContent.trim(),
            payment_account: el.rows[key].cells[5].textContent.trim(),
            status: el.rows[key].cells[6].textContent.trim(),
            payment_date: el.rows[key].cells[7].textContent.trim(),
          }
          this.json_data.push(row)
        }
      })
    },

    downloadCSV() {
      const content = this.getContents()
      const csv = this.convertToCSV(content)
      const filename = 'Affiliate.csv'
      const blob = new Blob([`\ufeff${csv}`], { type: 'text/csv; charset=utf-8' })
      this.downloadFile(blob, filename)
    },

    downloadUserInvoice(invoice) {
      const filename = invoice.split('/')[1]
      axios({
        url: `/api/affiliates/download_user_invoice?invoice=${invoice}`,
        method: 'GET',
        responseType: 'blob',
      }).then(res => {
        // const filename = 'affiliate_template.xls'
        const link = document.createElement('a')
        const url = URL.createObjectURL(res.data)
        link.setAttribute('href', url)
        link.setAttribute('download', filename)
        link.style.visibility = 'hidden'
        link.id = 'download_to_download'
        document.body.appendChild(link)
        document.getElementById(link.id).click()

        // remove to make sure a new csv file will be downloaded and not the previous one
        const element = document.getElementById(link.id)
        element.remove()
      })
    },

    downloadFile(blob, filename) {
      const link = document.createElement('a')
      const url = URL.createObjectURL(blob)
      link.setAttribute('href', url)
      link.setAttribute('download', filename)
      link.style.visibility = 'hidden'
      link.id = 'download_to_download'
      document.body.appendChild(link)
      document.getElementById(link.id).click()

      // remove to make sure a new csv file will be downloaded and not the previous one
      const element = document.getElementById(link.id)
      element.remove()
    },

    getContents() {
      const header = [
        'ID',
        'Request Date',
        'User',
        'Amount',
        'Payment Method',
        'Payment Account',
        'Status',
        'Payment Date',
        'Client Type',
      ]

      /* eslint-disable no-new-object */
      let el = new Object()
      /* eslint-enable no-new-object */
      el = this.$refs.printMe.$refs.table

      const rows = []
      Object.keys(el.rows).forEach(key => {
        if (key >= 1) {
          const row = []
          row.push(el.rows[key].cells[0].textContent.trim())
          row.push(el.rows[key].cells[1].textContent.trim())
          row.push(el.rows[key].cells[2].textContent.trim())
          row.push(el.rows[key].cells[3].textContent.trim())
          row.push(el.rows[key].cells[4].textContent.trim())
          row.push(el.rows[key].cells[5].textContent.trim())
          row.push(el.rows[key].cells[6].textContent.trim())
          row.push(el.rows[key].cells[7].textContent.trim())
          rows.push(row)
        }
      })

      rows.unshift(header)
      return JSON.stringify(rows)
    },

    convertToCSV(objArray) {
      const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray
      let str = ''
      for (let i = 0; i < array.length; i++) { // eslint-disable-line
        let line = ''
        for (const index in array[i]) { // eslint-disable-line
          if (line !== '') line += ','
          line += array[i][index]
        }
        str += `${line}\r\n` // eslint-disable-line
      }
      return str
    },

    sortFnID(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Number(rowX.id)
      const value2 = Number(rowY.id)
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnRegDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.created_at))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.created_at))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    sortFnPayDate(x, y, col, rowX, rowY) { // eslint-disable-line
      const value1 = Date.parse(this.filterFormatDate(rowX.updated_at))// get x
      const value2 = Date.parse(this.filterFormatDate(rowY.updated_at))// get y
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },

    sortFnAmount(x, y, col, rowX, rowY) { // eslint-disable-line
      let value1 = rowX.cumtomAmount
      let value2 = rowY.cumtomAmount
      // just take the number values from the string
      value1 = value1.replace(/[^0-9.-]+/g, '')
      value2 = value2.replace(/[^0-9.-]+/g, '')
      // test values
      return (value1 < value2 ? -1 : (value1 > value2 ? 1 : 0)) // eslint-disable-line
    },
    filterFormatDate(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()
      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`
      return [year, month, day].join('-')
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.mx-datepicker {
  width: 100% !important;
}

.invoice-register-table {
  thead tr{
    background: #f3f2f7;
  }

  th, td {
    font-size: 14px;
    text-align: center !important;
    vertical-align: middle !important;
  }

  td {
    height: 62px;
  }
}

.ir-btn-wrapper {
  width: 30rem;

  .ir-btn {
    width: 100%;
    max-width: 148px;
  }
}

.activation-btn {
  width: 100%;
  max-width: 16rem;
}

.modal .modal-header .close{
  margin: 2px
}
</style>
